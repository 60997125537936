.productItem {
    .productContent {
        .product {
            width: 200px;
            height: 250px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            border-radius: 5px;
            position: relative;
            border: 1px solid orangered;

            .image {
                width: 100%;
                height: 190px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 5px 5px 0px 0;
                }

                .totalSale {
                    background-color: rgba(255, 68, 0, 0.8);
                    color: white;
                    font-size: 11px;
                    font-weight: 600;
                    padding: 4px 8px;
                    text-align: center;
                    border-radius: 10px 0 0 10px;
                    position: absolute;
                    right: 0;
                    top: 5px;

                }

            }

            .title {
                position: relative;
                // height: 48px;

                .name {
                    background-color: rgba(255, 0, 0, 0.7);
                    max-width: 195px;
                    height: 30px;
                    line-height: 30px;
                    padding: 0 10px;
                    border-radius: 0 15px 15px 0;
                    margin-bottom: 3px;
                    position: absolute;
                    top: -15px;
                    z-index: 2;
                    display: inline;
                    overflow-wrap: break-word;

                    h4 {
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        width: 100%;

                    }
                }



                .price {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 30px;
                    padding-top: 35px;

                    .leftPrice {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        h4 {
                            color: orangered;
                            margin-left: 10px;
                            font-size: 16px;

                        }

                    }

                    .button {
                        button {
                            background-color: rgba(0, 153, 0, 0.8);
                            color: white;
                            height: 30px;
                            padding: 0 10px;
                            font-size: 16px;
                            font-weight: bold;
                            border: none;
                            border-radius: 15px 0 0 15px;
                            cursor: pointer;
                            transition: all .3s;

                            &:hover {
                                background-color: rgba(255, 68, 0, 0.8);
                            }
                        }
                    }


                }

            }

        }
    }
}