.productItemSettingMode {
    .productContent {
        .product {
            width: 200px;
            height: 250px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            border-radius: 5px;
            position: relative;

            .image {
                width: 100%;
                height: 190px;

                .upload {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .custom-file-upload {
                        display: inline-block;
                        position: relative;
                        font-size: 16px;

                        label {
                            cursor: pointer;
                            padding: 10px 15px;
                            border: 1px solid #ccc;
                            border-radius: 5px;

                            &:hover {
                                background-color: #f0f0f0;
                            }
                        }

                        input[type="file"] {
                            display: none;
                        }

                        i {
                            font-size: 180px;
                            color: rgb(245, 245, 245);
                            margin: 0 auto;
                            cursor: pointer;
                        }
                    }
                }

                i.deleteImg {
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    z-index: 4;
                    color: #cccccc;
                    cursor: pointer;
                    transition: all .3s;

                    &:hover {
                        color: orangered;
                    }
                }
            }

            .title {
                position: relative;
                height: 48px;

                .name {
                    input.name {
                        background-color: rgba(255, 0, 0, 0.7);
                        width: 180px;
                        height: 30px;
                        line-height: 30px;
                        padding: 0 10px;
                        border-radius: 0 15px 15px 0;
                        margin-bottom: 3px;
                        position: absolute;
                        top: -15px;
                        z-index: 2;
                        color: white;
                        border: none;
                        // outline: none;
                        font-size: 14px;
                        font-weight: bold;
                        cursor: pointer;
                        border: none;
                        padding: 0 10px;
                        margin: 0;
                        box-shadow: none;
                        -webkit-appearance: none;
                        /* Remove default styling in Safari */
                        -moz-appearance: none;
                        /* Remove default styling in Firefox */
                        appearance: none;
                        /* Remove default styling in modern browsers */
                        outline: none;

                        /* Remove the default outline */
                        /* Remove default styling in modern browsers */
                        &:focus {
                            outline: 1px solid silver;
                            background-color: white;
                            color: red;
                            // border-radius: 5px;
                        }


                    }

                }

                .price_button {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;


                    .price {
                        input.price {
                            position: absolute;
                            bottom: 0px;
                            left: 0px;
                            z-index: 2;
                            height: 30px;
                            width: calc(100% - 25px);
                            color: orangered;
                            padding: 0 10px;
                            border: none;
                            // outline: none;
                            outline-color: silver;
                            font-size: 16px;
                            font-weight: bold;
                            cursor: pointer;
                            border: none;
                            background: none;
                            padding: 0 10px;
                            margin: 0;
                            box-shadow: none;
                            -webkit-appearance: none;
                            /* Remove default styling in Safari */
                            -moz-appearance: none;
                            /* Remove default styling in Firefox */
                            appearance: none;
                            /* Remove default styling in modern browsers */
                            outline: none;

                            /* Remove the default outline */
                            /* Remove default styling in modern browsers */
                            &:focus {
                                outline: 1px solid silver;
                                border-radius: 5px;
                            }

                        }
                    }
                }
            }

            i.deleteMenuItem {
                position: absolute;
                right: 3px;
                bottom: 3px;
                z-index: 3;
                color: #cccccc;
                cursor: pointer;
                transition: all .3s;

                &:hover {
                    color: orangered;
                }
            }
        }
    }
}