#nhanVien {
    padding: 10px;

    .nhanVienTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p.nhanVien {
            color: red;
            font-weight: bold;
            font-size: 14px;

            button.addStaff {
                margin-left: 5px;
                height: 30px;
                width: 30px;
                text-align: center;
                line-height: 30px;
                border: none;
                background-color: orangered;
                color: white;
                border-radius: 50%;
                cursor: pointer;
                transition: all .3s;

                &:hover {
                    background-color: red;
                }

            }
        }



    }

    .nhanVienContent {
        margin-top: 10px;
        display: flex;
        gap: 15px;
        align-items: center;
        flex-wrap: wrap;

        .nhanVienWrap {
            position: relative;

            width: 340px;
            // padding: 10px;
            // border: 1px solid silver;

            .nhanVienItem {

                img {
                    width: 100%;
                    height: 340px;
                }

                h5,
                p {
                    height: 30px;
                    font-size: 16px;
                }

                input {
                    width: 100%;
                    height: 30px;
                    border: none;
                    outline: none;
                    font-size: 16px;

                    &.staffName {
                        // font-size: 16px;
                        font-weight: bold;
                    }
                }
            }

            button.deleteStaff {
                position: absolute;
                top: 5px;
                right: 5px;
            }

        }

        table {
            width: 100%;
            border-collapse: collapse;

            thead {
                th {
                    background-color: #009900;
                    color: white;
                    font-weight: 500;
                    padding: 5px 10px;

                    &.staffNo {
                        width: 20px;
                        padding-right: 3px;

                    }

                    &.staffPhone {
                        width: 120px;

                    }

                    &.staffPass {
                        width: 140px;
                    }

                    &.staffPosition {
                        width: 120px;

                    }

                    &.deleteStaff {
                        width: 20px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 1px 2px;
                        position: relative;

                        &.tdRight {
                            text-align: right;
                            padding-right: 5px;
                        }

                        &.tdCenter {
                            text-align: center;
                        }

                        input {
                            width: 100%;
                            height: 30px;
                            padding: 0px 5px;
                            font-size: 16px;
                            background-color: transparent;
                            border: none;
                            outline: none;

                            &.staffPass {
                                padding-right: 25px;
                            }

                        }

                        i.deleteStaff {
                            cursor: pointer;
                            color: orangered;
                            transition: all .3s;

                            &:hover {
                                color: red;
                            }
                        }

                        i.showPass {
                            position: absolute;
                            right: 10px;
                            top: 12px;
                            font-size: 10px;
                            color: #cccccc;
                            cursor: pointer;
                            transition: all .3s;

                            &:hover {
                                color: red;
                            }
                        }
                    }
                }
            }
        }

    }
}