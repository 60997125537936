#music {
    max-width: 1070px;
    margin: 0 auto;
    background-color: whitesmoke;

    .musicTitle {
        background-color: white;
        padding: 0 10px;

        p.music {
            font-size: 14px;
            font-weight: bold;
            color: orangered;

        }

        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .musicContent {
        height: calc(100vh - 45px);




        .musicContentSlider {
            margin-top: 5px;


            .sliderContent {
                background-color: rgba(30, 30, 30, 0.339);
                height: 60px;
                display: flex;
                align-items: center;
                gap: 10px;
                overflow: scroll;

                &::-webkit-scrollbar {
                    display: none;
                }


                .sliderItem {
                    flex-shrink: 0;
                    min-width: auto;
                    width: fit-content;
                    padding: 10px 20px;
                    border: 1px solid transparent;
                    border-radius: 10px;
                    background: #009900;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                    cursor: pointer;

                    &:first-child {
                        margin-left: 10px;
                    }

                    &:last-child {
                        margin-right: 10px;

                    }

                    span {
                        color: white;
                        font-size: 16px;
                        font-weight: bold;
                        display: inline;


                    }
                }



            }


        }







        .musicPlayerContent {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            align-items: flex-start;
            gap: 10px;

            .leftContent {
                width: 100%;
                max-height: calc(100vh - 120px);
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    display: none;
                }



                .album {
                    width: 100%;
                    overflow-x: scroll;
                    background: white;
                    padding: 20px 0 0;
                    margin-top: 10px;
                    border-radius: 10px;

                    &::-webkit-scrollbar {
                        display: none;
                    }





                    .albumSlider {
                        display: grid;
                        grid-template-columns: repeat(5, 1fr);
                        padding-left: 20px;

                        .albumWrap {
                            padding: 0 20px 20px 0;

                            .albumItem {
                                width: 200px;
                                height: 200px;
                                position: relative;
                                cursor: pointer;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }

                                p {
                                    color: white;
                                    background-color: #009900;
                                    height: 30px;
                                    font-size: 16px;
                                    font-weight: bold;
                                    line-height: 30px;
                                    padding: 0 10px;
                                    position: absolute;
                                    bottom: 10px;
                                    left: -10px;
                                }
                            }
                        }


                    }



                }

                .singer {
                    width: 100%;
                    overflow-x: scroll;
                    background: white;
                    padding: 20px 0 0;
                    margin-top: 10px;
                    border-radius: 10px;


                    &::-webkit-scrollbar {
                        display: none;
                    }





                    .singerSlider {
                        display: flex;
                        //lấy 1 row, chạy hết qua chiều ngang giúp mình

                        .singerWrap {
                            padding: 0 20px 20px 0;

                            &:first-child {
                                margin-left: 20px;
                            }

                            .singerItem {
                                width: 200px;
                                height: 230px;
                                position: relative;
                                cursor: pointer;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }

                                p {
                                    color: white;
                                    background-color: #009900;
                                    height: 30px;
                                    font-size: 16px;
                                    font-weight: bold;
                                    line-height: 30px;
                                    padding: 0 10px;
                                    position: absolute;
                                    bottom: 10px;
                                    left: -10px;
                                }
                            }
                        }


                    }



                }
            }

            .rightContent {
                width: 400px;
                padding: 10px 0;

                .musicPlayList {
                    height: calc(100vh - 250px);
                    background-color: white;
                    padding: 10px;
                    overflow: scroll;
                    margin: 10px 0 0 0;
                    border-radius: 10px;

                    &::-webkit-scrollbar {
                        display: none;
                    }


                    p {
                        font-size: 15px;
                        margin: 5px;
                        cursor: pointer;
                    }
                }

                .musicPlayer {
                    margin-top: 10px;
                    text-align: center;
                    padding: 15px 0 10px;
                    background-color: white;
                    // background-color: orangered;
                    border-radius: 10px;
                    position: relative;
                    overflow: hidden;


                    .timeSlider {
                        display: flex;
                        justify-content: center;
                        height: 37px;
                        // margin: 0 auto;


                        input {
                            width: 240px;
                            height: 2px;
                        }

                        p {
                            font-size: 11px;
                            font-weight: 600;
                            margin: 3px;
                            color: rgb(150, 150, 150);
                        }

                        div.speaker {
                            button {
                                width: 25px;
                                height: 25px;
                                border: none;
                                border: 1px solid #009900;
                                border-radius: 50%;
                                margin-left: 5px;
                                background-color: white;
                                color: #009900;
                                cursor: pointer;
                                margin-top: 2px;

                            }

                        }
                    }

                    .controller {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 20px;
                        margin-top: 5px;

                        button {
                            height: 25px;
                            width: 40px;
                            border: none;
                            border-radius: 5px;
                            cursor: pointer;
                            background-color: orangered;
                            color: white;
                            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                            transition: all .3s;

                            &:hover {
                                background-color: #009900;
                            }
                        }
                    }

                    .playingName {
                        width: 80%;
                        font-size: 10px;
                        margin: 0 auto;
                        overflow: hidden;


                        p {
                            height: 15px;
                            line-height: 15px;

                            text-align: left;
                            color: rgb(150, 150, 150);
                            font-weight: bold;
                            animation: slideRightToLeft 4s linear alternate infinite;
                        }
                    }


                }
            }
        }
    }


}

@keyframes slideRightToLeft {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-20%);
    }

    // 100% {
    //     transform: translateX(120%);
    // }

    // 100% {
    //     transform: translateX(-10%);
    // }

    // 100% {
    //     transform: translateX(50%);
    // }





}