* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    // background-color: whitesmoke;
}

#container {
    width: 1070px;
    // padding: 10px;
    margin: 0 auto;
    // background-color: white;

}